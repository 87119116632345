<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Thirteen</span><br>
      <span class="fw800 em3 fc-stats">The Keys to the Universe</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="The Keys to the Universe" videoUrl="https://www.youtube.com/embed/i_6pf6c2bbQ" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">The Path from Cause to Effect</h3>
        <p>
          Joshua Angrist was awarded the Nobel Prize in Economics in 2021 for his work on the analysis of causal relationships. As is the custom, Angrist delivered 
          a lecture to the Royal Swedish Academy of Sciences. However, due to the pandemic, the lecture was delivered virtually. This compromise is our gain. Angrist 
          delivered a master class in understanding how statistical methods can be used to illuminate the path from cause to effect. 
        </p>
        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <VideoTracker videoId="SEX8FC7UHbU" @update-view="handleUpdateView" />
          <small>You'll need to watch the entire video on this page so that it can give you credit for it. Your score will increase as you watch. Skipping ahead won't work.</small>
          <CalculatorLinks :score="mod1Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Regression Discontinuity Design</h3>
        <p>
          Regression Discontinuity Design (RD) is a useful research design when the treatment is assigned based on a cutoff score. For example, a business 
          might offer a rewards program to customers who spend more than a certain dollar amount. The cutoff score is the dollar amount. The RD design 
          allows the company to compare the outcomes of those who just barely qualify for the rewards program with those who just barely miss the cutoff and 
          identify the causal effect of the rewards program on sales.
        </p>
        <p>
          Say, for example, customers who spend $100 or more qualify for the rewards program. The hope is that the rewards program will increase future 
          sales among customers who qualify for it. Our first step in verifying this is to look at a scatterplot which compares future sales to the amount 
          spent in the current period. We would expect to see a discontinuity at the cutoff score of $100.
        </p>
        <div class="scatter-graph">
          <img :src="RDScatter">
        </div>
        <p>
          You can identify the treatment effect using regression, where the variable "Qualify" is either 0 when the customer does not qualify for the rewards 
          program, or 1 when they do. The regression model predicts future sales based on the amount spent in the current period and whether the customer 
          qualifies for the rewards program.
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center">
          <MathEquation :equation="'Future\\_Sales = \\beta_0 + \\beta_1Current\\_Spending + \\beta_2Qualify + \\beta_3(Qualify*Current\\_Spending) + \\varepsilon'"/>
        </p>
        <p>
          Here is the regression output for this model: 
        </p>
        <div class="rd-results">
          <img :src="RDResults">
        </div>
        <p>
          The coefficient for current spending is 0.2046, which means that for every dollar spent in the current period, future sales increase by about $0.20. 
          The coefficient for the interaction term (Qualify*Current Spending) is 0.03, which means that the rewards program <i>modifies</i> the effect of current 
          spending on future sales by an additional $0.03 per dollar spent. This coefficient was not statistically significant, however, and it's low magnitude 
          might mean we should not consider it different from zero. Finally, the coefficient for Qualify is 18.58. This means that customers who qualify for the 
          rewards program have future sales that are $18.58 higher than those who do not qualify.
        </p>
        <p>
          If there is no other reason why customer who spend $100 or more would have higher future sales, then we can conclude that the rewards program is 
          responsible for the increase in future sales. This is the power of the RD design.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Based on the effectiveness of the rewards program, the company changed the cutoff from $100 to $50. After some time passed, the company re-estimated 
            the regression model and found the following coefficients:
          </p>
          <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center flex-column align-content-center">
            <MathEquation :equation="'\\beta_0 = '+problemSets.mod2.b0"/>
            <MathEquation :equation="'\\beta_1 = '+problemSets.mod2.b1"/>
            <MathEquation :equation="'\\beta_2 = '+problemSets.mod2.b2"/>
            <MathEquation :equation="'\\beta_3 = '+problemSets.mod2.b3"/>
          </p>
          <!-- Question 1 -->
          <b>What is the effect of the rewards program on future sales?</b>
          <div class="input-group mb-3">
          <button ref="m2q1" class="input-group-text justify-content-center" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Report the effect" id="m2q1" :value="textbookResponses && textbookResponses.mod2 ? textbookResponses.mod2.q1 : ''" :style="checkM2Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <b>
            Use the initial regression results to predict future sales for a customer who initially spends $50, then use these new results to make the 
            same prediction. What is the difference in the predicted future sales?
          </b>
          <div class="input-group mb-3">
          <button ref="m2q2" class="input-group-text justify-content-center" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Report the change in predicted sales" id="m2q2" :value="textbookResponses && textbookResponses.mod2 ? textbookResponses.mod2.q2 : ''" :style="checkM2Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <CalculatorLinks :score="mod2Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Difference-in-Differences</h3>
        <p>
          Difference-in-Differences (DiD) is another research design that allows us to identify causal effects. In this design, we compare the change in 
          outcomes for a treatment group to the change in outcomes for a control group. The difference between these differences is the causal effect of the 
          treatment.
        </p>
        <p>
          A music streaming company which offers users a free option with ads might want to know if including more ads will result in higher revenue. 
          The company could compare the change in time spent listening for users who were exposed to more ads to the change in time spent listening for 
          users who were not. The difference between these differences would be the causal effect of the ads on time spent listening.
        </p>
        <p>
          The table below shows the intial and final time spent listening for the treatment and control groups.
        </p>
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th></th>
              <th>Month 1</th>
              <th>Month 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Treatment</td>
              <td>12.4 hours</td>
              <td>10.8 hours</td>
            </tr>
            <tr>
              <td>Control</td>
              <td>11.9 hours</td>
              <td>12.2 hours</td>
            </tr>
          </tbody>
        </table>
        <p>
          In month 1, both the treatment and control group were exposed to the same number of ads. In month 2, the treatment group was exposed to more ads, 
          while the control group saw no change in ads. The difference in time spent listening for the treatment group is -1.6 hours, while the difference 
          for the control group is 0.3 hours. The difference between these differences is -1.9 hours, which is the causal effect of the ads on time spent 
          listening.
        </p>
        <p>
          This can be done with regression by including a variable to indicate whether the observation is in the treatment group and another variable to 
          indicate whether the observation is in the second month. The interaction of these two variables will give the causal effect of the treatment. This 
          also allows you to control for potential confounding variables.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            The music streaming company has repeated their experiment and achieved the results below. 
          </p>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Month 1</th>
                <th>Month 2</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Treatment</td>
                <td>{{ problemSets.mod3.tb }} hours</td>
                <td>{{ problemSets.mod3.ta }} hours</td>
              </tr>
              <tr>
                <td>Control</td>
                <td>{{ problemSets.mod3.cb }} hours</td>
                <td>{{ problemSets.mod3.ca }} hours</td>
              </tr>
            </tbody>
          </table>
          <!-- Question 1 -->
          <b>What is the difference for the treatment group?</b>
          <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text justify-content-center" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Report the difference" id="m3q1" :value="textbookResponses && textbookResponses.mod3 ? textbookResponses.mod3.q1 : ''" :style="checkM3Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <b>What is the difference for the control group?</b>
          <div class="input-group mb-3">
          <button ref="m3q2" class="input-group-text justify-content-center" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Report the difference" id="m3q2" :value="textbookResponses && textbookResponses.mod3 ? textbookResponses.mod3.q2 : ''" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <b>What is the causal effect of the treatment?</b>
          <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text justify-content-center" style="width: 40px;">?</button>
          <input type="text" class="form-control" placeholder="Report the effect"  id="m3q3" :value="textbookResponses && textbookResponses.mod3 ? textbookResponses.mod3.q3 : ''" :style="checkM3Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
  
          <CalculatorLinks :score="mod3Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 4 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Instrumental Variables</h3>
        <p>
          Instrumental Variables (IV) is a research design that allows us to identify causal effects when there is endogeneity. Endogeneity occurs when the 
          treatment is correlated with the error term in the regression model. This can happen when there are unobserved variables that affect both the treatment 
          and the outcome. IV allows us to identify the causal effect of the treatment by finding a variable that is correlated with the treatment but not 
          correlated with the error term.
        </p>
        <p>
          For example, a company might pay to have their product featured in a popular movie. The hope is that this will increase sales, but how can we check 
          that it really does? An increase in sales observed after the movie is released could be due to the movie itself, or it could be due to some other 
          factor that is correlated with the movie's release, such as the season of the year or the state of the broader economy. It may also be difficult to 
          disentangle the effect of the product placement from other marketing efforts the company is making.
        </p>
        <p>
          One way we could tell is to survey customers and ask them if they saw the movie and if they bought our product. However, a correlation here does not 
          imply causation. It could be that people who are more likely to buy the product are also more likely to see the movie. This is where IV comes in. If 
          we find a variable that is correlated with seeing the movie but not correlated with other factors that might affect sales, we can use this variable 
          as an instrument to identify the causal effect of the movie on sales.
        </p>
        <p>
          A potential instrument here could be the distance from the respondent's home to the nearest movie theater showing the movie. This is likely to be 
          correlated with seeing the movie, but not with other factors that might affect sales. 
        </p>
        <p>
          In other words, a variable makes a good instrument if there is no way for it to impact the outcome except through the treatment. It might be reasonable 
          to assume that there is no way a person's distance from a movie theater could directly impact their decision to buy our product, except for the fact 
          that it changes the odds that they see the movie our product is featured in. 
        </p>
        <p>
          To identify the causal effect of the product placement on sales, we need to run a two-stage regression. First, we need to predict whether or not 
          the survey respondent saw the movie using the distance to the nearest movie theater as an instrument. 
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'Saw\\_Movie = \\beta_0 + \\beta_1Distance + \\beta_2Income + \\varepsilon'"/>
        </p>
        <p>
          If we are worried that there is some other variable that affects both the distance to the movie theater and the likelihood of buying our product, 
          we can include that variable in each stage of the regression. In this case, we have included income as a controlled variable. Using our survey 
          data, we can estimate each of these coefficients and use them to predict whether or not each respondent saw the movie.
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'\\widehat{Saw\\_Movie} = \\hat{\\beta_0} + \\hat{\\beta_1}Distance + \\hat{\\beta_2}Income'"/>
        </p>
        <p>
          The second stage of the regression uses this predicted value for whether or not the respondent saw the movie to predict whether or not they bought 
          our product.
        </p>
        <p style="margin-top:12px; margin-bottom:12px;" class="d-flex justify-content-center">
          <MathEquation :equation="'Bought\\_Product = \\beta_0 + \\beta_1\\widehat{Saw\\_Movie} + \\beta_2Income + \\varepsilon'"/>
        </p>
        <p>
          Notice that here, we still include income in our regression to hold it constant. The coefficient <MathEquation :equation="'\\beta_1'"/> is the causal 
          effect of seeing the movie on buying the product, so long as the exclusion restriction holds. 
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            Answer each of the questions below.
          </p>
          <!-- MULTIPLE CHOICE COMPONENT -->
          <div class="mc-container">
            <div v-for="(question, index) in problemSets.mod4" :key="question.id" class="mc-question" v-show="index === currentQuestionIndex">
              <b :style="checkM4Q1[index]">Question {{ index+1 }}</b>. {{ question.question }}
              <div v-for="answer in question.answers" :key="answer.id" class="mc-answer">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="question.id"
                    :id="'m4'+question.id+answer.id"
                    :value="answer.id"
                    :checked="textbookResponses.mod4[index] == answer.id"
                  />
                  <label class="form-check-label" :for="question.id+answer.id">
                    {{ answer.text }}
                  </label>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <button class="btn btn-light" type="button" @click="previousQuestion">Previous</button>
                <button class="btn btn-outline-secondary" type="button" @click="computeM4Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answers</button>
                <button class="btn btn-light" type="button" @click="nextQuestion">Next</button>
              </div>
            </div>  
          </div>
  
          <CalculatorLinks :score="mod4Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/1DWbkYS6XQY" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will use Two-Stage Least Squares (2SLS) to estimate the 
            causal effect of military service on earnings using an instrumental variable design. 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will implement an instrumental variable design to 
            determine the effect of schooling on income using a randomly chosen scholarship. 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import VideoTracker from '@/components/VideoTracker.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  import RDScatter from '@/assets/RDScatter.png';
  import RDResults from '@/assets/RDResults.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      VideoTracker,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch13',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        mod4Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        RDScatter,
        RDResults,
        renderEq,
        currentQuestionIndex: 0,
      }
    },
    created() {
      console.log('this is new');
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: {
                    watched: [],
                    total: 1,
                  },
                  mod2: {
                    q1: null,
                    q2: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                  },
                  mod4: {
                    q1: null,
                    q2: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0],
                  mod2: [0,0],
                  mod3: [0,0,0],
                  mod4: [0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.mod4Score = math.sum(this.userData.stats.gradeBook[this.ch].mod4);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM2Q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Regression Discontinuity Design',
          content: `Identify the effect from the estimated coefficients. Report the exact value of the effect.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });  
        const popoverM2Q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Regression Discontinuity Design',
          content: `Take the new prediction and subtract the initial prediction. Your answer should be accurate to two decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        }); 
        const popoverM3Q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'Difference-in-Differences',
          content: `Calculate the difference in time spent listening for the treatment group. Report the exact value.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        }); 
        const popoverM3Q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'Difference-in-Differences',
          content: `Calculate the difference in time spent listening for the control group. Report the exact value.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'Difference-in-Differences',
          content: `Calculate the causal effect of the treatment. Report the exact value.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM2Q1; popoverM2Q2; popoverM3Q1; popoverM3Q2; popoverM3Q3;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: {
                  watched: [],
                  total: 1,
                },
                mod2: {
                  q1: null,
                  q2: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                },
                mod4: {
                  q1: null,
                  q2: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      async handleUpdateView(data) {
        const watched = data.watched;
        const mergedSet = [...new Set([...watched, ...this.textbookResponses.mod1.watched])];
        data.watched = mergedSet;
        const total = data.total;
        const percent = (mergedSet.length / total);
        let score = math.ceil(percent * 80)/10;
        if(percent < 0.005 || score < 0) score = 0;
        if(percent > 0.98 || score > 8) score = 8;
        if(!this.userData){
          return null;
        } else {
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          if(mergedSet.length !== this.textbookResponses.mod1.watched.length){
            gradeMatrix[0] = score > gradeMatrix[0] ? score : gradeMatrix[0];
            const docRef = doc(database, "users", auth.currentUser.uid);
            await setDoc(docRef, {
              stats: {
                textbookResponses: {
                  [this.ch]: {
                    mod1: data,
                  }
                },
                gradeBook: {
                  [this.ch]: {
                    mod1: gradeMatrix,
                  }
                }
              }
            }, {merge: true});
            let docSnap = await getDoc(docRef);
            console.log(docSnap.data());
            this.userData = docSnap.data();
            this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
            this.mod1Score = math.sum(gradeMatrix);
          }
        }
      },
      problemSet() {
        const b0 = math.randomInt(1000, 6000)/1000;
        const b1 = math.randomInt(1000, 3000)/10000;
        const b2 = math.randomInt(500, 2000)/100;
        const b3 = math.randomInt(500, 1500)/1000;

        const tb = math.randomInt(90, 120)/10;
        const cb = math.randomInt(90, 120)/10;
        const ta = math.round(tb - math.randomInt(10, 30)/10 + math.randomInt(-15, 15)/10,1);
        const ca = math.round(cb + math.randomInt(-15, 15)/10,1);

        let questionSet = [
          {
            id: 'q1',
            question: 'What characteristics make a variable a good instrument in an instrumental variables analysis?',
            answers: [
              { id: 'a1', text: 'The variable must be highly correlated with the outcome of interest and uncorrelated with the treatment.' },
              { id: 'a2', text: 'The variable must be highly correlated with the treatment and uncorrelated with the error term in the outcome equation.' },
              { id: 'a3', text: 'The variable must be uncorrelated with both the treatment and the outcome.' },
              { id: 'a4', text: 'The variable must be a common cause of both the treatment and the outcome.' },
            ],
            correct: 'a2',
          },
          {
            id: 'q2',
            question: 'In the provided example of using distance to the nearest movie theater as an instrument, why is it important to include control variables such as income in the regression model?',
            answers: [
              { id: 'a1', text: 'To ensure that the instrument is perfectly correlated with the treatment.' },
              { id: 'a2', text: 'To increase the statistical power of the instrumental variables analysis.' },
              { id: 'a3', text: 'To account for potential confounders that might affect both the instrument and the outcome, ensuring the validity of the instrument.' },
              { id: 'a4', text: 'To directly measure the causal effect of the instrument on the outcome.' },
            ],
            correct: 'a3',
          },
        ];
        // randomize order of answer for each question
        for(let question of questionSet){
          question.answers.sort(() => Math.random() - 0.5);
        }

        return {
          mod2: {
            b0: b0,
            b1: b1,
            b2: b2,
            b3: b3,
          },
          mod3: {
            tb: tb,
            cb: cb,
            ta: ta,
            ca: ca,
          },
          mod4: questionSet,
        };
      },
      previousQuestion() {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
        } else {
          this.currentQuestionIndex = this.problemSets.mod4.length - 1;
        }
      },
      nextQuestion() {
        if (this.currentQuestionIndex < this.problemSets.mod4.length - 1) {
          this.currentQuestionIndex++;
        } else {
          this.currentQuestionIndex = 0;
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod2;
          const trueValue = data.b2;
          const studValue = document.getElementById('m2q1').value;
          const result = compare(trueValue, studValue, 0);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The effect of the rewards program on future sales is $${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Consider which coefficient represents the effect of the rewards program on future sales.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod2;
          const oldValue = 13.910105;
          const trueValue = data.b0 + data.b1*50 + data.b2 + data.b3*50 - oldValue;
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.01);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The change in predicted future sales is $${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `In the initial model, the customer did not qualify for the rewards program, so 'current_spending' is $50 and 'qualify' is 0. In the new model, the customer does qualify, so 'current_spending' is $50 and 'qualify' is 1. Use these values to make a prediction in each model and take the difference.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3;
          const trueValue = math.round(data.ta - data.tb,1);
          const studValue = document.getElementById('m3q1').value;
          const result = compare(trueValue, studValue, 0);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The difference for the treatment group is ${trueValue} hours.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the time spent listening for the treatment group in the second month and subtract the time spent listening in the first month. ${trueValue}`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q1: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3;
          const trueValue = math.round(data.ca - data.cb,1);
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The difference for the control group is ${trueValue} hours.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the time spent listening for the control group in the second month and subtract the time spent listening in the first month.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3;
          const trueValue = math.round(data.ta - data.tb - (data.ca - data.cb),1);
          const studValue = document.getElementById('m3q3').value;
          const result = compare(trueValue, studValue, 0);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The causal effect of the treatment is ${trueValue} hours.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the difference for the treatment group and subtract the difference for the control group.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 2}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q3: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM4Q1() {
        if(!this.userData){
          return null;
        } else {
          const studentChecked = [];
          let gradedArray = [];
          for(let question of this.problemSets.mod4){
            const trueValue = question.correct;
            const selectedAnswer = document.querySelector(`input[name=${question.id}]:checked`);
            const studValue = selectedAnswer ? selectedAnswer.value : null;
            studentChecked.push(studValue);
            const result = trueValue == studValue;
            gradedArray.push(result);
          }
          let feedbackArray = [
            {
              a1: `Question 1 is incorrect. While it is important for the variable to have a relationship with the outcome, the key requirement relates to its relationship with the treatment and error term.`,
              a2: `Question 1 is correct!`,
              a3: `Question 1 is incorrect. An instrument needs to have a specific relationship with the treatment to be useful, not just be uncorrelated with both the treatment and the outcome.`,
              a4: `Question 1 is incorrect. A valid instrument should not be a common cause of both the treatment and the outcome, as this would introduce endogeneity.`,
            },
            {
              a1: `Question 2 is incorrect. Ensuring perfect correlation between the instrument and treatment is not the main purpose of including control variables.`,
              a2: `Question 2 is incorrect. While control variables can increase the robustness of the analysis, their primary role relates to addressing confounders.`,
              a3: `Question 2 is correct!`,
              a4: `Question 2 is incorrect. The direct causal effect of the instrument on the outcome is not the focus when including control variables; think about the role of control variables in isolating the effect of the treatment.`,
            },
          ];
          this.modalTitle = 'Multiple Choice Results';
          this.modalMessage = '';
          for(let i = 0; i < gradedArray.length; i++){
            if(studentChecked[i]){
              this.modalMessage += feedbackArray[i][studentChecked[i]] + '<br><br>';
            } else {
              this.modalMessage += 'You did not answer question ' + (i+1) + '<br><br>';
            }
          }

          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod4;
          for(let i = 0; i < gradedArray.length; i++){
            if(gradeMatrix[i] == 0 && gradedArray[i]){
              gradeMatrix[i] = 2;
            } 
          }

          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod4: studentChecked,
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod4: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod4Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod2;
          const trueValue = data.b2;
          const studValue = this.textbookResponses && this.textbookResponses.mod2 ? this.textbookResponses.mod2.q1 : null;
          const result = compare(trueValue, studValue, 0);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod2;
          const oldValue = 13.910105;
          const trueValue = data.b0 + data.b1*50 + data.b2 + data.b3*50 - oldValue;
          const studValue = this.textbookResponses && this.textbookResponses.mod2 ?  this.textbookResponses.mod2.q2 : null;
          const result = compare(trueValue, studValue, 0.01);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.problemSets.mod3;
          const trueValue = math.round(data.ta - data.tb,1);
          const studValue = this.textbookResponses && this.textbookResponses.mod3 ? this.textbookResponses.mod3.q1 : null;
          const result = compare(trueValue, studValue, 0);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3;
          const trueValue = math.round(data.ca - data.cb,1);
          const studValue = this.textbookResponses && this.textbookResponses.mod3 ? this.textbookResponses.mod3.q2 : null;
          const result = compare(trueValue, studValue, 0);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch].mod3;
          const trueValue = math.round(data.ta - data.tb - (data.ca - data.cb),1);
          const studValue = this.textbookResponses && this.textbookResponses.mod3 ? this.textbookResponses.mod3.q3 : null;
          const result = compare(trueValue, studValue, 0);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM4Q1() {
        if(!this.userData){
          return null;
        } else {
          let styleArray = [];
          for(let [index, question] of this.problemSets.mod4.entries()) {
            const trueValue = question.correct; 
            const studValue = this.textbookResponses && this.textbookResponses.mod4 ? this.textbookResponses.mod4[index] : null; 
            const result = trueValue == studValue;
            if(studValue && result){
              styleArray.push({color: 'green'})
            } else if (studValue) {
              styleArray.push({color: 'red'})
            } else {
              styleArray.push(null);
            }
          }
          return styleArray;
        }
      },
    }
  }
  </script>
  
<style scoped>
.scatter-graph {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.scatter-graph img {
  width: 100%;
  max-width: 800px;
}
.rd-results {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  
}
.rd-results img {
  width: 100%;
  max-width: 600px;
  padding: 6px;
  border: 1.25px solid #ccc;
  border-radius: 10px;
}
</style>