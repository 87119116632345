<template>
  <div class="container col-md-6 offset-md-3">
  
  <!-- #region CHAPTER AND TITLE -->
  <div class="container">
    <div class="book-title-div">
      <span class="fw600 em1-50">Chapter Ten</span><br>
      <span class="fw800 em3 fc-stats">Randomized Controlled Trials</span>
    </div>
  </div>
  <!-- #endregion -->
  
  <!-- SUB NAVIGATION BAR -->
  <nav>
    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
      <button class="nav-link active" id="nav-learn-tab" data-bs-toggle="tab" data-bs-target="#nav-learn" type="button" role="tab" aria-controls="nav-learn" aria-selected="true">Learn</button>
      <button class="nav-link" id="nav-explore-tab" data-bs-toggle="tab" data-bs-target="#nav-explore" type="button" role="tab" aria-controls="nav-explore" aria-selected="false">Explore</button>
      <button class="nav-link" id="nav-complete-tab" data-bs-toggle="tab" data-bs-target="#nav-complete" type="button" role="tab" aria-controls="nav-complete" aria-selected="false">Complete</button>
    </div>
  </nav>
  
  <!-- SUB NAV BAR CONTENT -->
  <div class="tab-content" id="nav-tabContent">
    <!-- TEXTBOOK -->
    <div class="tab-pane fade show active" id="nav-learn" role="tabpanel" aria-labelledby="nav-learn-tab" tabindex="0">
      
      <VideoBox class="mt-4" videoTitle="Randomized Controlled Trials" videoUrl="https://www.youtube.com/embed/DJVStCntONA" startAt="0"/>

      <!-- #region MODULE 1 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Ceteris Paribus</h3>
        <p>
          There is a strong positive correlation between schooling and income. People who get more schooling tend to earn higher incomes. 
          But is this a causal relationship? Does more schooling cause higher income? To know for sure, we would need to observe how much 
          schooling people get, and then observe how much income they earn. Then, we would hop into our time machine and go back in time and 
          give people more or less schooling than they otherwise had gotten and observe the changes that result in their income. If we found 
          that people who were given more schooling earned more income, we could conclude that schooling causes higher income, because it is 
          the <b><i>only</i></b> thing that we changed, and so it is the only thing that could have caused the results. 
        </p>
        <p>
          Economists and statisticians refer to this condition as <b>ceteris paribus</b>, which is latin for "all other things being equal." 
          To truly know that one thing causes another, we must hold all other things constant. But, without a time machine, this is hard to do. 
          Luckily, there is an experimental design which gets us close.
        </p>
        <VideoBox videoUrl="https://www.youtube.com/embed/eGRd8jBdNYg" videoTitle="Randomized Trials: The Ideal Weapon" startAt="0" />
        <h4 class="fc-stats">Four Reasons X is Correlated with Y</h4>
        <p>
          There are four reasons why two variables might be correlated with each other. These are: 
        </p>
        <ol>
          <li><b>X causes Y</b> - A change in X does something to cause a change in Y.</li>
          <li><b>Y causes X</b> - The cause and effect are reversed.</li>
          <li><b>Z causes both X and Y</b> - Some other factor Z is causing changes in X and Y, which are otherwise unrelated.</li>
          <li><b>Random Chance</b> - The correlation is due to random chance.</li>
        </ol>
        <p>
          Randomized controlled trials (RCTs) are the best way to eliminate reverse causation and confounding variables. But they don't eliminate 
          seeing a result due to random chance. It could just be that your treatment and control groups were weird, an unrepresentative of the population. 
          Luckily, we have statistical tools to help us determine if our results are due to random chance or not.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Try it Yourself</h4>
          <p>
            Use the applet below to gather a random sample and then randomly assign them to the treatment or control group. Then compare the averages 
            for each group across a handful of variables. Try it many times, with small, medium, and large sample sizes. Be amazed at how the law of large 
            numbers makes each group similar! 
          </p>
          <SampleAssignment @score="handleScore" />
          <CalculatorLinks :score="mod1Score" :scoreTotal="4"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <!-- #region MODULE 2 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">The Two-Sample T-Test</h3>
        <p>
          In order to determine if the difference between two groups is due to random chance or not, we use the two-sample t-test. This test 
          compares the means of two groups and determines if the difference between them is statistically significant. When we have reason to 
          believe that the variance of both groups is the same (more on this later), we use the pooled t-test: 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em2">
          <MathEquation :equation="`t = \\frac{\\bar{x}_1 - \\bar{x}_2}{\\sqrt{\\frac{(n_1 - 1)s_1^2 + (n_2 - 1)s_2^2}{n_1 + n_2 - 2}\\lgroup\\frac{1}{n_1}+\\frac{1}{n_2}\\rgroup}}`"/>
        </p>
        <h4>Example</h4>
        <p>
          A large e-commerce company is testing a new search algorithm to see if it increases sales. They randomly assign 1000 customers to 
          the treatment group and 1000 customers to the control group. After a week, they find that the treatment group had an average of $100 
          in sales, while the control group had an average of $95 in sales. The standard deviation of sales was $19 for the treatment group and 
          $21 for the control group. Is this difference statistically significant?
        </p>
        <h5>Step One: Compute the difference in means</h5>
        <p>
          It's nice to start with an easy step. The difference in means is <MathEquation :equation="`\\bar{x}_1 - \\bar{x}_2 = 100 - 95 = 5`"/>. <br>
          <small>In the notation of the equation, Group 1 is the treatment group and Group 2 is the control group.</small>
        </p>
        <h5>Step Two: Compute the Pooled Variance</h5>
        <p>
          In the equation for t, the pooled variance is this part:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`s_p^2 = \\frac{(n_1 - 1)s_1^2 + (n_2 - 1)s_2^2}{n_1 + n_2 - 2}`"/>
        </p>
        <p>
          It isn't so scary when you break it down. The pooled variance is the weighted average of the variances of the two groups. 
          In this case, it is <MathEquation :equation="`s_p^2 = \\frac{(999)(19^2) + (999)(21^2)}{1998} = 401`"/>.
        </p>
        <p>
          It didn't take long for this big equation to get a lot less scary. Here is what we have so far:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`t = \\frac{5}{\\sqrt{401\\lgroup\\frac{1}{n_1}+\\frac{1}{n_2}\\rgroup}}`"/>
        </p>
        <h5>Step Three: Compute the t-statistic</h5>
        <p>
          Filling in our sample sizes, we get:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`t = \\frac{5}{\\sqrt{401\\lgroup\\frac{1}{1000}+\\frac{1}{1000}\\rgroup}} = 5.5832`"/>
        </p>
        <p>
          Ours was a two-tailed test with 1,998 degrees of freedom (<MathEquation :equation="`n_1 + n_2 - 2`"/>). 
        </p>
        <TStatPValue />
        <p>
          You'll get 0.0000 in the calculator above. If we want something more precise, its 0.00000002683781486. This means that 
          if the null hypothesis that <MathEquation :equation="`\\bar{x}_1 = \\bar{x}_2`"/> is true (that the treatment has no effect), 
          we would only see a difference between the groups this large or larger once in every 37,300,000 tries. This is incredibly 
          unlikely! More likely is that there <i>is</i> a difference between the groups. The treatment really is having an effect. 
        </p>
        <p>
          In the lingo of the statistician, we would say that the difference between the groups is <b>statistically significant</b>.
        </p>
        <h4 class="fc-stats">Statistical Significance vs. Practical Significance</h4>
        <p>
          Just because a difference is statistically significant doesn't mean it is practically significant. In our example, the difference 
          in sales was $5. This is statistically significant, but is it practically significant? Would you change your search algorithm for 
          a $5 increase in sales? Maybe, maybe not. This is a decision that you would have to make based on your business goals and the cost 
          of changing the algorithm. It is important to recognize that just because we can show a difference between two groups is very unlikely 
          to be due to chance, doesn't mean that the difference is worth acting on.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A new cold medicine is being tested for effectiveness. The control group was given a placebo and the treatment group was given the medicine. 
            The number of days it took them to recover from their cold was recorded. Here is the data:
          </p>
          <p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{treatment} = \\{${problemSets.mod2.treatment.join(',')}\\}`"/>
          </p><p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{control} = \\{${problemSets.mod2.control.join(',')}\\}`"/>
          </p>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m2q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}_1 - \\bar{x}_2'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the difference in the sample means" id="m2q1" :value="textbookResponses.mod2.q1" :style="checkM2Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m2q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'S.E.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the standard error of the sample" id="m2q2" :value="textbookResponses.mod2.q2" :style="checkM2Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
          <button ref="m2q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the t-statistic" id="m2q3" :value="textbookResponses.mod2.q3" :style="checkM2Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m2q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'d.f.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the degrees of freedom" id="m2q4" :value="textbookResponses.mod2.q4" :style="checkM2Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 5 -->
          <div class="input-group mb-3">
          <button ref="m2q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'p'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the p-value" id="m2q5" :value="textbookResponses.mod2.q5" :style="checkM2Q5">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 6 -->
          <div class="input-group mb-3">
          <button ref="m2q6" class="input-group-text" style="width: 110px;">Result</button>
          <select class="form-select" id="m2q6" :value="textbookResponses.mod2.q6" :style="checkM2Q6">
            <option :selected="textbookResponses.mod2.q6 === 'reject'" value="reject">Reject the null hypothesis</option>
            <option :selected="textbookResponses.mod2.q6 === 'do_not_reject'" value="do_not_reject">Do not reject the null hypothesis</option>
          </select>
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 7&8 -->
          <b>Calculate the 95% Confidence Interval for the difference in the means.</b>
          <div class="input-group mb-3">
          <button ref="m2q7" class="input-group-text" style="width: 120px;">Lower Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the lower bound" id="m2q7" :value="textbookResponses.mod2.q7" :style="checkM2Q7">
          <button ref="m2q8" class="input-group-text" style="width: 120px;">Upper Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the upper bound" id="m2q8" :value="textbookResponses.mod2.q8" :style="checkM2Q8">
          <button class="btn btn-outline-secondary" type="button" @click="computeM2Q7" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div> 
          <TStatPValue />
          <TStatCrit />
          <CalculatorLinks :score="mod2Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->
      
      <!-- #region MODULE 3 -->
      <div class="intructions-module rounded container">
        <h3 class="fc-stats">Assumptions</h3>
        <p>
          We have been careening through 'hypothesis testing' at a reckless pace. Its a very important topic, but it isn't one you are likely to 
          encounter ever again unless you become a statistician or a scientist. So what, if anything, should you take away from this? Here are a 
          few key points:
        </p>
        <ul>
          <li class="mb-2">
            Hypothesis testing allows us to determine if the difference between two groups is due to random chance or not. Results which are 
            statistically significant are unlikely to be due to random chance.
          </li>
          <li class="mb-2">
            Causal identification is the process of determining if one thing causes another. Whether or not a result should be interpreted 
            as causal depends on the design of the study, not statistical significance. Randomized controlled trials are the best way to determine causality.
          </li>
          <li class="mb-2">
            Living up to the standards required for causal identification are <i>incredibly</i> difficult. Even when it comes to randomized controlled 
            trials, there are many ways to mess them up. Most commonly, researchers fail to notice a way in which their treatment and control groups 
            were not actually randomly assigned.
          </li>
          <li class="mb-2">
            Even when a research design is good, the results can be misleading. Studies which show no effect are less likely to be published than 
            studies which show an effect. This means that the <a href="https://en.wikipedia.org/wiki/Publication_bias" target="_blank">published literature is biased</a> 
            towards showing effects which may not actually exist. 
          </li>
          <li class="mb-2">
            Without extensive training in statistics and research design, it is difficult to know if a study is well-designed or not. Skepticism is good, 
            but don't be too quick to dismiss a study just because it doesn't show what you want it to show. When people say, "Do your own research", it 
            is often best to leave it to the experts.
          </li>
        </ul>

        <h4 class="fc-stats">Unequal Variance</h4>
        <p>
          I want to emphasize some of the points above by diving a little bit deeper into the two-sample t-test. In the previous section, we computed 
          the standard error of the test using the pooled variance. But there is a hidden assumption when we compute the standard error this way. 
        </p>
        <p>
          The example we used was about an e-commerce company testing a new search algorithm. By pooling the variances of the two groups together, 
          we assumed that the population variance would be the same for the treatment and control group. We saw that the means of the two groups were 
          different ($100 vs $95), but we assumed that the different variances (19² vs 21²) were due to random chance. This might not be true. The algorithm 
          might be increasing average sales, but it might also be changing the variance of sales.
        </p>
        <p>
          Statistics is rife with things like this. Little assumptions that need to be true in order for our results to be valid. Most of the work 
          statisticians do is in finding out what conclusions we can draw if we fail to meet these assumptions, and designing ways to eak out a little 
          more understanding from the data we have. Indeed, some statisticians named Welch and Satterthwaite helped us with this particular problem.
        </p>
        <p>
          If we don't assume equal variance, then we need to modify our calculation of the t-statistic and the degrees of freedom. The formula for the 
          t-statistic is:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-50">
          <MathEquation :equation="`t = \\frac{\\bar{x}_1 - \\bar{x}_2}{\\sqrt{\\frac{s_1^2}{n_1}+\\frac{s_2^2}{n_2}}}`"/>
        </p>
        <p>
          And the formula for the degrees of freedom is:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-50">
          <MathEquation :equation="`d.f. = \\frac{\\lgroup\\frac{s_1^2}{n_1}+\\frac{s_2^2}{n_2}\\rgroup^2}{\\frac{(s_1^2/n_1)^2}{n_1-1}+\\frac{(s_2^2/n_2)^2}{n_2-1}}`"/>
        </p>
        <h4>Example</h4>
        <p>
          A large e-commerce company is testing a new search algorithm to see if it increases sales. They randomly assign 1000 customers to 
          the treatment group and 1000 customers to the control group. After a week, they find that the treatment group had an average of $100 
          in sales, while the control group had an average of $95 in sales. The standard deviation of sales was $19 for the treatment group and 
          $21 for the control group. Is this difference statistically significant?
        </p>
        <h5>Step One: Compute the difference in means</h5>
        <p>
          It's nice to start with an easy step. The difference in means is <MathEquation :equation="`\\bar{x}_1 - \\bar{x}_2 = 100 - 95 = 5`"/>. <br>
          <small>In the notation of the equation, Group 1 is the treatment group and Group 2 is the control group.</small>
        </p>
        <h5>Step Two: Compute the Standard Error</h5>
        <p>
          This is the denominator of our t-statistic formula. 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`S.E. = \\sqrt{\\frac{s_1^2}{n_1}+\\frac{s_2^2}{n_2}} = \\sqrt{\\frac{361}{1000}+\\frac{441}{1000}} = 0.895544527`"/>
        </p>
        <h5>Step Three: Compute the t-statistic</h5>
        <p>
          Filling in our difference in means and standard error, we get:
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`t = \\frac{5}{0.895544527} = 5.5832`"/>
        </p>
        <p>
          This is the same as what we got in the last section because our treatment and control group had the same sample size.
        </p>
        <h5>Step Four: Compute the degrees of freedom</h5>
        <p>
          The Welch-Satterthwaite equation is an approximation meant to correct for a bias introduced by trying to estimate two population variances 
          instead of one. It is a complicated equation, so it is best to break it into pieces: 
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`\\lgroup\\frac{s_1^2}{n_1}+\\frac{s_2^2}{n_2}\\rgroup^2 = \\lgroup\\frac{361}{1000}+\\frac{441}{1000}\\rgroup^2 = 0.641601`"/>
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`\\frac{(s_1^2/n_1)^2}{n_1-1} = \\frac{(361/1000)^2}{999} = 0.000130451451`"/>
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`\\frac{(s_2^2/n_2)^2}{n_2-1} = \\frac{(441/1000)^2}{999} = 0.000194675676`"/>
        </p>
        <p style="margin-top:12px; margin-bottom:24px;" class="d-flex justify-content-center em1-25">
          <MathEquation :equation="`d.f. = \\frac{0.641601}{0.000130451451 + 0.000194675676} = 1,973.385`"/>
        </p>
        <p>
          You cannot have a fractional number of degrees of freedom, and so the proceedure is to always <b>round down</b> the result. 
          In this case, we have 1,973 degrees of freedom. Notice that we have lost quite a few degrees of freedom by not assuming equal variance.
        </p>
        <p>
          Ours is a two-tailed test with 1,973 degrees of freedom. 
        </p>
        <TStatPValue />
        <p>
          You'll still get 0.0000 in the calculator above, but the precise value is 0.00000002688092304. We still conclude that the result 
          is statistically significant. Because our conclusion didn't change, we would call it "robust" to the assumption of equal variance.
        </p>

        <div class="mt-3" v-if="userData"><h4 class="fc-prove">Prove Your Understanding</h4>
          <p>
            A new cold medicine is being tested for effectiveness. The control group was given a placebo and the treatment group was given the medicine. 
            The number of days it took them to recover from their cold was recorded. Here is the data:
          </p>
          <p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{treatment} = \\{${problemSets.mod2.treatment.join(',')}\\}`"/>
          </p><p class="d-flex justify-content-center">
            <MathEquation :equation="`x_{control} = \\{${problemSets.mod2.control.join(',')}\\}`"/>
          </p>
          <p>
            This time we will compute the t-statistic and degrees of freedom without assuming equal variances.
          </p>
          <!-- Question 1 -->
          <div class="input-group mb-3">
          <button ref="m3q1" class="input-group-text" style="width: 110px;"><MathEquation :equation="'\\bar{x}_1 - \\bar{x}_2'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the difference in the sample means" id="m3q1" :value="textbookResponses.mod3.q1" :style="checkM3Q1">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q1" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 2 -->
          <div class="input-group mb-3">
          <button ref="m3q2" class="input-group-text" style="width: 110px;"><MathEquation :equation="'S.E.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the standard error of the sample" id="m3q2" :value="textbookResponses.mod3.q2" :style="checkM3Q2">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q2" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 3 -->
          <div class="input-group mb-3">
          <button ref="m3q3" class="input-group-text" style="width: 110px;"><MathEquation :equation="'t'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the t-statistic" id="m3q3" :value="textbookResponses.mod3.q3" :style="checkM3Q3">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q3" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 4 -->
          <div class="input-group mb-3">
          <button ref="m3q4" class="input-group-text" style="width: 110px;"><MathEquation :equation="'d.f.'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the degrees of freedom" id="m3q4" :value="textbookResponses.mod3.q4" :style="checkM3Q4">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q4" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 5 -->
          <div class="input-group mb-3">
          <button ref="m3q5" class="input-group-text" style="width: 110px;"><MathEquation :equation="'p'"/></button>
          <input type="text" class="form-control" placeholder="Calculate the p-value" id="m3q5" :value="textbookResponses.mod3.q5" :style="checkM3Q5">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q5" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 6 -->
          <div class="input-group mb-3">
          <button ref="m3q6" class="input-group-text" style="width: 110px;">Result</button>
          <select class="form-select" id="m3q6" :value="textbookResponses.mod3.q6" :style="checkM3Q6">
          <option :selected="textbookResponses.mod3.q6 === 'reject'" value="reject">Reject the null hypothesis</option>
          <option :selected="textbookResponses.mod3.q6 === 'do_not_reject'" value="do_not_reject">Do not reject the null hypothesis</option>
          </select>
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q6" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div>
          <!-- Question 7&8 -->
          <b>Calculate the 95% Confidence Interval for the difference in the means.</b>
          <div class="input-group mb-3">
          <button ref="m3q7" class="input-group-text" style="width: 120px;">Lower Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the lower bound" id="m3q7" :value="textbookResponses.mod3.q7" :style="checkM3Q7">
          <button ref="m3q8" class="input-group-text" style="width: 120px;">Upper Bound</button>
          <input type="text" class="form-control" placeholder="Calculate the upper bound" id="m3q8" :value="textbookResponses.mod3.q8" :style="checkM3Q8">
          <button class="btn btn-outline-secondary" type="button" @click="computeM3Q7" data-bs-toggle="modal" data-bs-target="#variableModal">Check Answer</button>
          </div> 
          <TStatPValue />
          <TStatCrit />
          <CalculatorLinks :score="mod3Score" :scoreTotal="8"/>
        </div>
        
      </div> 
      <!-- #endregion -->

      <VideoBox class="mt-4" videoTitle="Conclusion" videoUrl="https://www.youtube.com/embed/zmIu9u_kgtM" startAt="0"/>

      <!-- #region DATA CHANGER -->
      <div v-if="userData" class="data-changer">
        <div class="em1-50 fw600">Questions Reset</div>
        <div>You can reset the questions with randomly generated numbers for more practice.</div>
        <button class="btn btn-warning mt-3" @click="resetAll">Reset all questions (but keep my points)</button>
      </div>
      <!-- #endregion -->

    </div>
    <!-- EXPLORATORY LESSON -->
    <div class="tab-pane fade" id="nav-explore" role="tabpanel" aria-labelledby="nav-explore-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="ExploratoryLessonIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Exploratory Lesson {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your exploratory lesson. In this week's Exploratory Lesson, we will conduct a two-sample t-test and 
            deepen our understanding of hypothesis testing by testing the difference between two proportions. 
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ lssnScore }} out of 20 points on this lesson.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/lesson'" class="btn btn-primary open-spreadsheet-button">Open Exploratory Lesson</router-link>
      </div>
    </div>
    <!-- SPREADSHEET ASSIGNMENT -->
    <div class="tab-pane fade" id="nav-complete" role="tabpanel" aria-labelledby="nav-complete-tab" tabindex="0">
      <div class="row mt-4">
        <div class="col-4">
          <img :src="SpreadsheetAssignmentIcon" width="100%">
        </div>
        <div class="col-8">
          <h3 class="fc-stats">Spreadsheet Assignment {{ ch.substring(2) }}</h3>
          <div style="text-align: justify;">
            The button below will open your Spreadsheet Assignment. In this Spreadsheet Assignment, you will conduct a two-sample hypothesis test 
            and then give a brief explanation of the results.
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end fc-prove fw600">
            You have scored {{ assnScore }} out of 30 points on this assignment.
          </div>
        </div>
  
      </div>
      <div class="d-flex align-items-center justify-content-center mt-4">
        <router-link :to="'/stats/'+ch.substring(2)+'/assignment'" class="btn btn-primary open-spreadsheet-button">Open Spreadsheet Assignement</router-link>
      </div>
    </div>
  </div>
  
  </div>
  
  <!-- #region MODAL -->
  <div class="modal fade" id="variableModal" tabindex="-1" aria-labelledby="variableModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img :src="probabilibot" width="20%">
          <h1 class="modal-title fs-5" id="variableModal">{{ modalTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body col" v-html="modalMessage"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion -->
  
  </template>
  
  <script>
  import { doc, getDoc, setDoc } from "firebase/firestore"; 
  import { auth, database, compare, renderEq, setUpStatsData } from '@/main';
  import { Popover } from 'bootstrap';
  import { studentt } from 'jstat';

  import MathEquation from '@/components/MathEquation.vue';
  import CalculatorLinks from '@/components/CalculatorLinks.vue';
  import VideoBox from '@/components/VideoBox.vue';
  import SampleAssignment from '@/components/SampleAssignment.vue';
  import TStatCrit from "@/components/TStatCrit.vue";
  import TStatPValue from '@/components/TStatPValue.vue';
  
  import { create, all } from 'mathjs'
  const math = create(all);
  
  import probabilibot from '@/assets/Probabilibot.png';
  import ExploratoryLessonIcon from '@/assets/ExploratoryLessonIcon.png';
  import SpreadsheetAssignmentIcon from '@/assets/SpreadsheetAssignmentIcon.png';
  
  export default {
    name: "statsMainPage",
    components: {
      MathEquation,
      CalculatorLinks,
      VideoBox,
      SampleAssignment,
      TStatCrit,
      TStatPValue,
    },
    props: ['tab'],
    data() {
      return {
        ch: 'ch10',
        userData: null,
        problemSets: null,
        textbookResponses: null,
        setUpStatsData,
        mod1Score: 0,
        mod2Score: 0,
        mod3Score: 0,
        assnScore: 0,
        lssnScore: 0,
        modalTitle: 'Loading...',
        modalMessage: '',
        probabilibot,
        ExploratoryLessonIcon,
        SpreadsheetAssignmentIcon,
        renderEq,
      }
    },
    created() {
      this.loadUserInformation();
    },
    methods: {
      async loadUserInformation() {
        await this.setUpStatsData();
        await this.pullProblemSet();
        await this.setPopovers();
      },
      async pullProblemSet() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        let docSnap = await getDoc(docRef);
        let data = docSnap.data();
        
        // ADD VALUES FOR THE CHAPTER PROBLEM SET
        if(data.stats.problemSets[this.ch].length == 0 || !data.stats.problemSets[this.ch]){
          await setDoc(docRef, {
            stats: {
              problemSets: {
                [this.ch]: this.problemSet(),
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER TEXTBOOK RESPONSE CATEGORIES IF THEY'RE NOT THERE
        if(Object.keys(data.stats.textbookResponses[this.ch]).length == 0){
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod1: null,
                  mod2: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                  },
                  mod3: {
                    q1: null,
                    q2: null,
                    q3: null,
                    q4: null,
                    q5: null,
                    q6: null,
                    q7: null,
                    q8: null,
                  },
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        // ADD CHAPTER GRADEBOOK IF MISSING
        if(!data.stats.gradeBook[this.ch].mod1){
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: [0,0,0,0],
                  mod2: [0,0,0,0,0,0,0,0],
                  mod3: [0,0,0,0,0,0,0,0],
                  assignment: null,
                  lesson: null,
                }
              }
            }
          }, {merge: true});
          docSnap = await getDoc(docRef);
          data = docSnap.data();
        }
  
        this.userData = data;
        this.problemSets = data.stats.problemSets[this.ch];
        this.textbookResponses = data.stats.textbookResponses[this.ch];
        this.mod1Score = math.sum(this.userData.stats.gradeBook[this.ch].mod1);
        this.mod2Score = math.sum(this.userData.stats.gradeBook[this.ch].mod2);
        this.mod3Score = math.sum(this.userData.stats.gradeBook[this.ch].mod3);
        this.assnScore = this.userData.stats.gradeBook[this.ch].assignment ? this.userData.stats.gradeBook[this.ch].assignment : 0;
        this.lssnScore = this.userData.stats.gradeBook[this.ch].lesson ? this.userData.stats.gradeBook[this.ch].lesson : 0;
      },
      async setPopovers() {
        await new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (this.userData !== null) {
                    clearInterval(intervalId);
                    resolve(this.userData);
                }
            }, 100); // Check every 100 milliseconds
        });
        const popoverM2Q1 = new Popover(this.$refs.m2q1, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Take the difference in the sample means. You can do it either way, but typically you take the treatment group minus the control group. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });   
        const popoverM2Q2 = new Popover(this.$refs.m2q2, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Find the standard error of the difference in sample means. This is the denominator of the t-statistic equation. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q3 = new Popover(this.$refs.m2q3, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Compute the t-statistic. This is the difference in the sample means divided by the standard error. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q4 = new Popover(this.$refs.m2q4, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the degrees of freedom when assuming equal variances. Your final answer should be an integer.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q5 = new Popover(this.$refs.m2q5, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the p-value using the calculator below. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q6 = new Popover(this.$refs.m2q6, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Decide whether to reject the null hypothesis. If the p-value is less than 0.05, you should reject the null hypothesis. Otherwise, you should not reject the null hypothesis.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q7 = new Popover(this.$refs.m2q7, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the lower bound of the 95% confidence interval for the difference in the means. Take the difference and subtract the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM2Q8 = new Popover(this.$refs.m2q8, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the upper bound of the 95% confidence interval for the difference in the means. Take the difference and add the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q1 = new Popover(this.$refs.m3q1, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Take the difference in the sample means. You can do it either way, but typically you take the treatment group minus the control group. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q2 = new Popover(this.$refs.m3q2, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Find the standard error of the difference in sample means. This is the denominator of the t-statistic equation. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q3 = new Popover(this.$refs.m3q3, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Compute the t-statistic. This is the difference in the sample means divided by the standard error. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q4 = new Popover(this.$refs.m3q4, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the degrees of freedom when assuming unequal variances. Your final answer should be an integer.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q5 = new Popover(this.$refs.m3q5, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the p-value using the calculator below. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q6 = new Popover(this.$refs.m3q6, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Decide whether to reject the null hypothesis. If the p-value is less than 0.05, you should reject the null hypothesis. Otherwise, you should not reject the null hypothesis.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q7 = new Popover(this.$refs.m3q7, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the lower bound of the 95% confidence interval for the difference in the means. Take the difference and subtract the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        const popoverM3Q8 = new Popover(this.$refs.m3q8, {
          container: 'body',
          title: 'Two-Sample T-Test',
          content: `Calculate the upper bound of the 95% confidence interval for the difference in the means. Take the difference and add the standard error times the critical value for t. Your final answer should be accurate up to four decimal places.`,
          trigger: 'hover focus',
          placement: 'left',
          html: true,
        });
        popoverM2Q1; popoverM2Q2; popoverM2Q3; popoverM2Q4; popoverM2Q5; popoverM2Q6; popoverM2Q7; popoverM2Q8; popoverM3Q1; popoverM3Q2; popoverM3Q3; popoverM3Q4; popoverM3Q5; popoverM3Q6; popoverM3Q7; popoverM3Q8;
      },
      async resetAll() {
        const docRef = doc(database, "users", auth.currentUser.uid);
        await setDoc(docRef, {
          stats: {
            problemSets: {
              [this.ch]: this.problemSet()
            }
          }
        }, {merge: true});
        await setDoc(docRef, {
          stats: {
            textbookResponses: {
              [this.ch]: {
                mod1: null,
                mod2: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                  q7: null,
                  q8: null,
                },
                mod3: {
                  q1: null,
                  q2: null,
                  q3: null,
                  q4: null,
                  q5: null,
                  q6: null,
                  q7: null,
                  q8: null,
                },
              }
            }
          }
        }, {merge: true});
        const docSnap = await getDoc(docRef);
        this.userData = docSnap.data();
        this.problemSets = this.userData.stats.problemSets[this.ch];
      },
      problemSet() {
        let n1 = Math.floor(Math.random() * 10) + 10;
        let n2 = Math.floor(Math.random() * 10) + 10;
        const treatment1 = Array.from({length: n1}, () => Math.floor(Math.random() * 5) + 1);
        const control1 = Array.from({length: n2}, () => Math.floor(Math.random() * 7) + 1);
        n1 = Math.floor(Math.random() * 10) + 10;
        n2 = Math.floor(Math.random() * 10) + 10;
        const treatment2 = Array.from({length: n1}, () => Math.floor(Math.random() * 50) + 20);
        const control2 = Array.from({length: n2}, () => Math.floor(Math.random() * 50) + 30);
        return {
          mod1: null,
          mod2: {
            treatment: treatment1,
            control: control1,
          },
          mod3: {
            treatment: treatment2,
            control: control2,
          }
        }
      },
      async handleScore(score) {
        if(!this.userData){
          return null;
        } else {
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod1;
          for(let i = 0; i < gradeMatrix.length; i++){
            if(gradeMatrix[i] == 0){
              gradeMatrix[i] = score[i];
            }
          }
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              gradeBook: {
                [this.ch]: {
                  mod1: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.mod1Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const trueValue = math.abs(x1 - x2);
          const studRaw = document.getElementById('m2q1').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const addendum = x1 - x2 > 0 ? 'Note that the average for the treatment group is larger than the control, which means this drug is making colds worse!' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The difference in the sample means is ${math.round(x1 - x2,4)}. ${addendum}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the average of each group and subtract them from each other.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q1: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studValue = document.getElementById('m2q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard error of the sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The standard error is the denominator of the t-statistic equation. It is the square root of the pooled variance times the sum of the reciprocals of the sample sizes.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studRaw = document.getElementById('m2q3').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const neg = x1 - x2 < 0 ? '-' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic is ${neg+math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The t-statistic is the difference in the sample means divided by the standard error of the sample.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q3: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = n1 + n2 - 2;
          const studValue = document.getElementById('m2q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The degrees of freedom are ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The degrees of freedom are the sum of the sample sizes minus 2.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = 2 * (1 - (studentt.cdf(math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2)), n1 + n2 - 2)));
          const studValue = document.getElementById('m2q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value is the probability of seeing a difference this large or larger if the null hypothesis is true.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const t = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const p = 2 * (1 - studentt.cdf(t, n1 + n2 - 2));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = document.getElementById('m2q6').value;
          const result = trueValue == studValue;
          const feedbackString = trueValue == 'reject' ? 'Because this is less than or equal to 0.05, we reject the null hypothesis' : 'Because this is more than 0.05, we do not reject the null hypothesis';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. ${feedbackString}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. When the p-value is less than 0.05, we reject the null hypothesis. Otherwise, we do not reject the null hypothesis.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM2Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = document.getElementById('m2q7').value;
          const studRawUpper = document.getElementById('m2q8').value;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(resultLower && resultUpper){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The lower bound of the 95% confidence interval for this sample is ${math.round(trueValueLower,4)} and the upper bound is ${math.round(trueValueUpper,4)}.`;
          } else if(resultLower) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The lower bound is correct, but the upper bound is not.`;
          } else if(resultUpper) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The upper bound is correct, but the lower bound is not.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the lower bound of the 95% confidence interval for this sample. For a two-sample test, take the difference in the means and add and subtract the product of the standard error and the t-value for a 95% confidence interval.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod2;
          if(gradeMatrix[6] == 0 && resultLower){gradeMatrix[6] = 1}
          if(gradeMatrix[7] == 0 && resultUpper){gradeMatrix[7] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod2: {
                    q7: studRawLower,
                    q8: studRawUpper,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod2: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod2Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const trueValue = math.abs(x1 - x2);
          const studRaw = document.getElementById('m3q1').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const addendum = x1 - x2 > 0 ? 'Note that the average for the treatment group is larger than the control, which means this drug is making colds worse!' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The difference in the sample means is ${math.round(x1 - x2,4)}. ${addendum}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Take the average of each group and subtract them from each other.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[0] == 0 && result){gradeMatrix[0] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q1: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.sqrt(v1/n1 + v2/n2);
          const studValue = document.getElementById('m3q2').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The standard error of the sample is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The standard error is the denominator of the t-statistic equation. It is the square root of the sum of the reciprocals of the sample sizes times the variance of each group.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[1] == 0 && result){gradeMatrix[1] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q2: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2);
          const studRaw = document.getElementById('m3q3').value;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          const neg = x1 - x2 < 0 ? '-' : '';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The t-statistic is ${neg+math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The t-statistic is the difference in the sample means divided by the standard error of the sample.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[2] == 0 && result){gradeMatrix[2] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q3: studRaw,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))));
          const studValue = document.getElementById('m3q4').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The degrees of freedom are ${trueValue}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Use the degrees of freedom calculation for unequal variances.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[3] == 0 && result){gradeMatrix[3] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q4: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = 2 * (1 - (studentt.cdf(math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2), math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1)))))));
          const studValue = document.getElementById('m3q5').value;
          const result = compare(trueValue, studValue, 0.0001);
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value is ${math.round(trueValue,4)}.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value is the probability of seeing a difference this large or larger if the null hypothesis is true.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[4] == 0 && result){gradeMatrix[4] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q5: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const t = math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2);
          const p = 2 * (1 - studentt.cdf(t, math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))))));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = document.getElementById('m3q6').value;
          const result = trueValue == studValue;
          const feedbackString = trueValue == 'reject' ? 'Because this is less than or equal to 0.05, we reject the null hypothesis' : 'Because this is more than 0.05, we do not reject the null hypothesis';
          if(result){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. ${feedbackString}`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The p-value for this sample is ${math.round(p,4)}. When the p-value is less than 0.05, we reject the null hypothesis. Otherwise, we do not reject the null hypothesis.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[5] == 0 && result){gradeMatrix[5] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q6: studValue,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
      async computeM3Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt(v1/n1 + v2/n2);
          const dof = math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))));
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = document.getElementById('m3q7').value;
          const studRawUpper = document.getElementById('m3q8').value;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(resultLower && resultUpper){
            this.modalTitle = 'Correct!';
            this.modalMessage = `The lower bound of the 95% confidence interval for this sample is ${math.round(trueValueLower,4)} and the upper bound is ${math.round(trueValueUpper,4)}.`;
          } else if(resultLower) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The lower bound is correct, but the upper bound is not.`;
          } else if(resultUpper) {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `The upper bound is correct, but the lower bound is not.`;
          } else {
            this.modalTitle = 'Incorrect';
            this.modalMessage = `Calculate the lower bound of the 95% confidence interval for this sample. For a two-sample test, take the difference in the means and add and subtract the product of the standard error and the t-value for a 95% confidence interval.`;
          }
          let gradeMatrix =  this.userData.stats.gradeBook[this.ch].mod3;
          if(gradeMatrix[6] == 0 && resultLower){gradeMatrix[6] = 1}
          if(gradeMatrix[7] == 0 && resultUpper){gradeMatrix[7] = 1}
          const docRef = doc(database, "users", auth.currentUser.uid);
          await setDoc(docRef, {
            stats: {
              textbookResponses: {
                [this.ch]: {
                  mod3: {
                    q7: studRawLower,
                    q8: studRawUpper,
                  },
                }
              },
              gradeBook: {
                [this.ch]: {
                  mod3: gradeMatrix,
                }
              }
            }
          }, {merge: true});
          let docSnap = await getDoc(docRef);
          this.userData = docSnap.data();
          this.textbookResponses = this.userData.stats.textbookResponses[this.ch];
          this.mod3Score = math.sum(gradeMatrix);
        }
      },
    }, 
    computed: {
      checkM2Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const trueValue = math.abs(x1 - x2);
          const studRaw = this.userData.stats.textbookResponses[this.ch].mod2.q1;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const studRaw = this.userData.stats.textbookResponses[this.ch].mod2.q3;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = n1 + n2 - 2;
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = 2 * (1 - studentt.cdf(math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2)), n1 + n2 - 2));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q5;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const t = math.abs(x1 - x2) / math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const p = 2 * (1 - studentt.cdf(t, n1 + n2 - 2));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = this.userData.stats.textbookResponses[this.ch].mod2.q6;
          const result = trueValue == studValue;
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const studRawLower = this.textbookResponses.mod2.q7;
          const studRawUpper = this.textbookResponses.mod2.q8;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          if(studRawLower && resultLower){
            return {
              borderColor: 'green'
            }
          } else if (studRawLower) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM2Q8() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt((((n1-1)*v1 + (n2-1)*v2) / (n1 + n2 - 2)) * (1/n1 + 1/n2));
          const dof = n1 + n2 - 2;
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = this.textbookResponses.mod2.q7;
          const studRawUpper = this.textbookResponses.mod2.q8;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(studRawUpper && resultUpper){
            return {
              borderColor: 'green'
            }
          } else if (studRawUpper) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q1() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const trueValue = math.abs(x1 - x2);
          const studRaw = this.userData.stats.textbookResponses[this.ch].mod3.q1;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q2() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.sqrt(v1/n1 + v2/n2);
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q2;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q3() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2);
          const studRaw = this.userData.stats.textbookResponses[this.ch].mod3.q3;
          const studValue = studRaw ? math.abs(studRaw) : null;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q4() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q4;
          const result = compare(trueValue, studValue, 0.0001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q5() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const trueValue = 2 * (1 - studentt.cdf(math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2), math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))))));
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q5;
          const result = compare(trueValue, studValue, 0.001);
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q6() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const t = math.abs(x1 - x2) / math.sqrt(v1/n1 + v2/n2);
          const p = 2 * (1 - studentt.cdf(t, math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))))));
          const trueValue = p < 0.05 ? 'reject' : 'do_not_reject';
          const studValue = this.userData.stats.textbookResponses[this.ch].mod3.q6;
          const result = trueValue == studValue;
          if(studValue && result){
            return {
              borderColor: 'green'
            }
          } else if (studValue) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q7() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt(v1/n1 + v2/n2);
          const dof = math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))));
          const trueValueLower = diff - (se * studentt.inv(0.975, dof));
          const studRawLower = this.userData.stats.textbookResponses[this.ch].mod3.q7;
          const studRawUpper = this.userData.stats.textbookResponses[this.ch].mod3.q8;
          const resultLower = compare(trueValueLower, studRawLower, 0.0001) || compare(trueValueLower, -studRawUpper, 0.0001) ? true : false;
          if(studRawLower && resultLower){
            return {
              borderColor: 'green'
            }
          } else if (studRawLower) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
      checkM3Q8() {
        if(!this.userData){
          return null;
        } else {
          const data = this.userData.stats.problemSets[this.ch];
          const x1 = math.mean(data.mod2.treatment);
          const x2 = math.mean(data.mod2.control);
          const diff = x1 - x2;
          const v1 = math.variance(data.mod2.treatment);
          const v2 = math.variance(data.mod2.control);
          const n1 = data.mod2.treatment.length;
          const n2 = data.mod2.control.length;
          const se = math.sqrt(v1/n1 + v2/n2);
          const dof = math.floor((v1/n1 + v2/n2)**2 / (((v1/n1)**2 / (n1-1)) + ((v2/n2)**2 / (n2-1))));
          const trueValueUpper = diff + (se * studentt.inv(0.975, dof));
          const studRawLower = this.userData.stats.textbookResponses[this.ch].mod3.q7;
          const studRawUpper = this.userData.stats.textbookResponses[this.ch].mod3.q8;
          const resultUpper = compare(trueValueUpper, studRawUpper, 0.0001) || compare(trueValueUpper, -studRawLower, 0.0001) ? true : false;
          if(studRawUpper && resultUpper){
            return {
              borderColor: 'green'
            }
          } else if (studRawUpper) {
            return {
              borderColor: 'red'
            }
          } else {
            return null;
          }
        }
      },
    }
  }
  </script>
  
<style scoped>

</style>